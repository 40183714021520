import React from 'react';
import {Button} from 'antd';
import Helmet from '../project/Helmet';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import generalNoParameterFormPC from '../fetch/generalNoParameterFormPC';
import {createFetchBackupSave, createFetchBackupSaveJournal, createFetchBackupRestore} from '../../backend/apiCalls';
import {GLOBAL_DATA} from '../../constants/globalData';
import {BackupStatus} from '../../constants/propTypesDefinitions';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import InfoAttributeGroup from '../general/InfoAttributeGroup';
import {Trans, t} from '@lingui/macro';
import DateTime from '../general/DateTime';


/**
 * @fero
 */

class BackupPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.BACKUP_STATUS]: BackupStatus.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const status = this.props[GLOBAL_DATA.BACKUP_STATUS];
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];

        return <React.Fragment>
            <Helmet
                title={t`Záloha`}
            />
            <div className="full-size-height">
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Posledná úplná záloha:</Trans>,
                            value: <DateTime timeString={status.created_at}/>
                        },
                        {
                            title: <Trans>Posledný žurnál</Trans>,
                            value: <DateTime timeString={status.last_record}/>
                        },
                        {
                            title: <Trans>Veľkosť zálohy</Trans>,
                            value: <span>{status.size + ' '}<Trans>bajtov</Trans></span>
                        },
                    ]}
                />
                <div className="mx-3 d-flex justify-content-start">
                    <FormModal
                        openNode={<Button><Trans>Zálohovať žurnál</Trans></Button>}
                        values={{}}
                        onFinishSuccessful={reload}
                        title={<Trans>Záloha žurnálu</Trans>}
                        Form={generalNoParameterFormPC(
                            <Trans>Chcete zálohovať žurnál?</Trans>,
                            createFetchBackupSaveJournal(),
                        )}
                        Response={null}
                        Failed={generalFailedPC(t`Záloha žurnálu zlyhala`)}
                    />
                    <FormModal
                        openNode={<Button type="primary"><Trans>Zálohovať všetko</Trans></Button>}
                        values={{}}
                        onFinishSuccessful={reload}
                        title={<Trans>Úplná záloha</Trans>}
                        Form={generalNoParameterFormPC(
                            <Trans>Chcete spustiť zálohovanie žurnálu aj databázy?</Trans>,
                            createFetchBackupSave(),
                        )}
                        Response={null}
                        Failed={generalFailedPC(t`Úplná záloha databázy zlyhala`)}
                    />
                    <FormModal
                        openNode={<Button type="danger"><Trans>Obnoviť zo zálohy</Trans></Button>}
                        values={{}}
                        onFinishSuccessful={reload}
                        title={<Trans>Obnova zo zálohy</Trans>}
                        Form={generalNoParameterFormPC(
                            <span>
                                <Trans>Naozaj chcete spustiť obnovu zálohy? Všetky údaje a akcie od</Trans>
                                {' '}<DateTime timeString={status.last_record}/>{' '}<Trans>budú stratené!!!</Trans>
                            </span>,
                            createFetchBackupRestore(),
                        )}
                        Response={null}
                        Failed={generalFailedPC(t`Obnova databázy zlyhala`)}
                    />
                </div>
            </div>
        </React.Fragment>;
    }
}

export default withDataHOC([GLOBAL_DATA.BACKUP_STATUS, GLOBAL_DATA.RELOAD_DATA])(BackupPage);